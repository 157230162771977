import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "@/pages/Home.vue";
import About from "@/pages/About.vue";
import Contact from "@/pages/Contact.vue";
import Therapists from "@/pages/Therapists.vue";
import Booking from "@/pages/Booking.vue";
import Massage from "@/pages/Massage.vue";
import Makeup from "@/pages/Makeup.vue";
import Fitness from "@/pages/Fitness.vue";
import Beauty from "@/pages/Beauty.vue";
import TC from "@/pages/TC.vue";
import CP from "@/pages/CP.vue";
import PP from "@/pages/PP.vue";
import Cancel from "@/pages/Cancel.vue";
import Confirmed from "@/pages/Confirmed.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact
  },
  {
    path: "/therapists",
    name: "therapists",
    component: Therapists
  },
  {
    path: "/massage",
    name: "massage",
    component: Massage
  },
  {
    path: "/makeup",
    name: "makeup",
    component: Makeup
  },
  {
    path: "/beauty",
    name: "beauty",
    component: Beauty
  },
  {
    path: "/fitness-yoga",
    name: "fitness",
    component: Fitness
  },
  {
    path: "/booking",
    name: "booking",
    component: Booking
  },
  {
    path: "/tc",
    name: "tc",
    component: TC
  },
  {
    path: "/cp",
    name: "cp",
    component: CP
  },
  {
    path: "/pp",
    name: "pp",
    component: PP
  },
  {
    path: "/redirect/cancelled",
    name: "cancel",
    component: Cancel
  },
  {
    path: "/redirect/confirmed",
    name: "confirmed",
    component: Confirmed
  }
]
// /redirect/confirmed
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
