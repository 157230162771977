<template>
    <main>
        <Heading title="Fitness & Yoga" />
        <FitnessShowcase />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue'
import FitnessShowcase from '@/components/services/FitnessShowcase.vue';

export default {
    name: "Fitness",
    metaInfo: {
        title: "Fitness",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        FitnessShowcase
    }
}
</script>

<style scoped>

</style>
