<template>
    <main>
        <Heading title="Cancellation Policy" />

        <v-container class="my-4">
            <p class="text-h6 red--text">CANCELLATION POLICY US</p>
            <p class="text-justify">
                We understand that sometimes you may need to cancel your appointment with us. If you need to do so, we kindly ask that you provide us with at least 24 hours notice so that we can make arrangements with our therapists.
            </p>
            <p class="text-justify">
                If you cancel your appointment less than 2 hours before the scheduled start time, we reserve the right to charge a cancellation fee of 50% of the total cost of the appointment. If you cancel less than 2 hours before the scheduled start time, we reserve the right to charge the full cost of the appointment.
            </p>
            <p class="text-justify">
                We understand that unforeseen circumstances can arise, such as illness or unexpected travel delays, and we will make every effort to accommodate your needs. However, repeated cancellations or no-shows may result in being unable to book future appointments with us.
            </p>
            <p class="text-justify">
                If you need to cancel your appointment, please call or email us as soon as possible. We appreciate your understanding and cooperation in this matter.
            </p>
            <p class="text-justify">
                Thank you for choosing “Meissa London” for your massage and beauty needs.
            </p>


            <p class="text-h6 red--text">CONTACT US</p>
            <p class="text-justify">
                In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site, please contact us at:
                <br><br>
<pre>
MEISSA LONDON LIMITED
7 Farquharson road
Ground Floor Flat
Croydon, Surrey CR0 2UH
United Kingdom
Phone: 020 8080 9898
bookings@meissalondon.com
</pre> 
                <br><br>
                <!-- These terms of use were created using Termly's <a href="https://termly.io/products/terms-and-conditions-generator/">Terms and Conditions Generator</a>. -->
            </p>
        </v-container>
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';

export default {
    name: "CP",
    metaInfo: {
        title: "Cancellation Policy",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading
    }
}
</script>

<style scoped>

</style>
