import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c(VContainer,[_c(VRow,{staticClass:"my-4",attrs:{"data-aos":"fade-up"}},[_c(VCol,{staticClass:"text-justify",attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c('h2',[_vm._v("For Booking Related Details")]),_c('p',[_vm._v(" Thank you for choosing Meissa London for your treatment. To make a reservation or to inquire about our services, please contact us using one of the methods. ")]),_c('p',[_vm._v(" Our website offers an easy and convenient online booking system. Simply visit our website and follow the prompts to book your reservation. "),_c('router-link',{attrs:{"to":"/booking"}},[_vm._v("Book Online")])],1)]),_c(VCol,{staticClass:"text-justify",attrs:{"cols":"12","lg":"6","md":"6","sm":"12"}},[_c(VList,{attrs:{"two-line":""}},[_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-phone")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Telephone")]),_c(VListItemSubtitle,[_c('a',{staticStyle:{"color":"#0984e3"},attrs:{"href":"tel:02080809898"}},[_vm._v("020 8080 9898")])])],1)],1),_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-email")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Email")]),_c(VListItemSubtitle,[_c('a',{staticStyle:{"color":"#0984e3"},attrs:{"href":"mailto:bookings@meissalondon.com"}},[_vm._v("bookings@meissalondon.com")])])],1)],1),_c(VListItem,[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"#00b894"}},[_vm._v("mdi-whatsapp")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Chat on WhatsApp")]),_c(VListItemSubtitle,[_c('a',{staticStyle:{"color":"#00b894"},attrs:{"href":"https://api.whatsapp.com/send?phone=447780133016","target":"_blank"}},[_vm._v("Click here")])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }