<template>
    <div class="main my-4">
        <v-container>
            <h1>Therapies in detail</h1>
            <p class="text-justify">
                Our specialist therapist's are known to provide a tailor made treatment that involves the manipulation of the body's soft tissues, including muscles, tendons, and ligaments, to promote relaxation and improve physical and emotional well-being. Our primary goal is to reduce stress and tension in your body, which can help to alleviate pain, increase range of motion, and promote overall healing.
            </p>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/3764568/pexels-photo-3764568.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Deep tissue massage</h1>
                    <p class="subtitle text-justify">
                        Deep tissue massage (DTM) is a treatment that uses strong pressure and kneading motions to help relieve the deeper layers of muscle and connective tissues. The therapist will use their hands, elbows and knees to move the tissue around to improve blood flow and alleviate painA deep tissue massage can also help with injury recovery by breaking down scar tissue and adhesions in the muscles.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/6187290/pexels-photo-6187290.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Swedish Massage</h1>
                    <p class="subtitle text-justify">
                        Swedish massage is a whole-body therapeutic massage treatment that works the soft tissues and muscles to help restore health - creating a calming and balancing effect on the nervous system. The therapist applies gentle, rhythmic pressure to the recipient's body in order to help reduce tension and improve circulationSwedish massage is one of the most popular forms of massage therapy. It can help you relax, give you more energy, and may help after an injury. Swedish massage involves long, kneading strokes combined with rhythmic tapping strokes and movement of the joints.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/sports-massage.jpg"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Sports Massage</h1>
                    <p class="subtitle text-justify">
                        Sports massage is a great way to ease the pain and tension caused by physical activity. It can help to improve joint function, reduce muscle aches and pain, and promote relaxation. Sports massage can be beneficial for individuals of all ages, genders and fitness levels. Anyone can benefit from sports massage but athletes and people who regularly participate in physical activities are generally the best candidates. It is useful for anyone who exercises to increase flexibility, prevent injuries and help with healing after a sports injury. Sports massage is specifically tailored to different sports and sporting injuries. It is not a relaxing form of massage and can often be quite strenuous. It works by stretching tight muscles, stimulating inactive muscles and improving the condition of the soft tissue The aim of sports massage is to reduce the stress and tension that can build up in the soft tissues of the body during exercise.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/thai-massage.jpg"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Thai Massage</h1>
                    <p class="subtitle text-justify">
                        Thai massage is a well-known and popular form of relaxation therapy. It is based on a Swedish massage technique that originated in the 1800s. The therapist uses their hands and arms to massage the recipient's body. Thai massage is an umbrella term that refers to a range of therapeutic treatments that originated in Thailand more than 2,500 years ago. The primary goal of Thai massage is to restore balance to the body by releasing stress and tension through deep muscle manipulation. Sessions usually last between 60 and 90 minutes and are typically performed on a mat on the floor. Both the client and the practitioner wear loose, comfortable clothing during the session.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/pregnancy-massage.jpg"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Pregnancy Massage</h1>
                    <p class="subtitle text-justify">
                        During pregnancy, your body undergoes a lot of changes. Some of these changes can be very uncomfortable, and you may feel like you need to find relief. Luckily, there are many ways to get relief from the symptoms of pregnancy. One of the most popular ways to ease your symptoms is by getting a pregnancy massage. Here are some of the top benefits of getting massages during pregnancy: • Decreases tension • Increases circulation • Reduces swelling • Relieves aches and pains • Improves sleep • Improves your mood.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/5240677/pexels-photo-5240677.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Reflexology</h1>
                    <p class="subtitle text-justify">
                        Reflexology is a form of hands-on therapy that uses pressure and friction to promote healing in the feet, hands, and other parts of the body. It is a popular complementary therapy used to treat a variety of health problems, including stress, anxiety, chronic pain.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/lymph-massage.jpg"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Lymphatic Drainage Massage</h1>
                    <p class="subtitle text-justify">
                        Lymphatic drainage massage (LDM) is a widely used complementary and alternative medicine (CAM) technique for treating a variety of conditions, including pain relief, stress reduction and improved circulation. LDM is usually performed by a trained massage therapist or physical therapist in a clinical setting using specialized massage techniques designed to stimulate the lymphatic system and encourage the flow of lymph fluid through the body.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import aosMixin from "@/mixins/aos.js";

export default {
    name: "ThingsToKnow",
    mixins: [aosMixin],
}
</script>

<style scoped>

</style>
