<template>
    <main>
        <Heading title="Our Massages" />
        <ThingsToKnow />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import ThingsToKnow from '@/components/home/ThingsToKnow.vue';

export default {
    name: "Massage",
    metaInfo: {
        title: "Massage",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        ThingsToKnow,
    }
}
</script>

<style scoped>

</style>
