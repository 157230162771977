<template>
    <div class="main">
        <v-container>
            <v-select label="Select a service" v-model="searchInput" :value="searchInput" :items="['Our Team', 'Massage', 'Beauty', 'Hair & Makeup', 'Fitness']"></v-select>
            <!-- <v-text-field v-model="searchInput" label="Search by name or service"></v-text-field> -->
        </v-container>

        <v-container>
            <v-row>
                <v-col v-for="person in filteredProfiles" :key="person.id" cols="12" sm="12" md="6" lg="3">
                    <v-card>
                        <v-img height="260" @click="person.reveal = true" src="@/assets/placeholder.png" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)">
                            <v-card-text>
                                <span class="text-h6">{{ person.name }}</span> <br>
                                <span class="text-caption">{{ person.profile }}</span>
                            </v-card-text>
                        </v-img>

                        <v-expand-transition>
                            <v-card v-if="person.reveal" class="transition-fast-in-fast-out v-card--reveal" style="height: 100%;">
                                <v-card-text class="pb-0">
                                    <p class="text--primary">
                                        {{ person.profile }}
                                    </p>
                                    <p>
                                        {{ person.bio }}
                                    </p>
                                </v-card-text>
                                <v-card-actions>
                                    <!-- <v-btn color="primary" :href="person.redirect" link outlined>Book Me</v-btn> -->
                                    <v-btn @click="person.reveal = false" color="primary" text>Close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-expand-transition>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "People",
    data() {
        return {
            searchInput: "Our Team",

            people: [
                {
                    id: 1,
                    name: "Maili",
                    profile: "Massage",
                    reveal: false,
                    // imgSrc: "@/assets/placeholder.png",
                    bio: "Maili is a registered osteopath who is passionate about health and well-being. Maili qualified as a massage therapist in 2011 and has been a practicing therapist since then. ",
                    redirect: "/"
                },
                {
                    id: 2,
                    name: "Jane",
                    profile: "Massage",
                    reveal: false,
                    // imgSrc: "@/assets/placeholder.png",
                    bio: "Maili is a registered osteopath who is passionate about health and well-being. Maili qualified as a massage therapist in 2011 and has been a practicing therapist since then. ",
                    redirect: "/"
                },
                {
                    id: 3,
                    name: "Preeti",
                    profile: "Massage",
                    reveal: false,
                    // imgSrc: "@/assets/placeholder.png",
                    bio: "Maili is a registered osteopath who is passionate about health and well-being. Maili qualified as a massage therapist in 2011 and has been a practicing therapist since then. ",
                    redirect: "/"
                },
                {
                    id: 4,
                    name: "Mohammad",
                    profile: "Massage",
                    reveal: false,
                    // imgSrc: "@/assets/placeholder.png",
                    bio: "Maili is a registered osteopath who is passionate about health and well-being. Maili qualified as a massage therapist in 2011 and has been a practicing therapist since then. ",
                    redirect: "/"
                },
                {
                    id: 5,
                    name: "Manish",
                    profile: "Hair & Makeup",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Experienced hairdresser specializing in styling and haircuts. Provides personalized consultations, wedding & special occasion hairstyles, and hair extensions.",
                    redirect: "/"
                },
                {
                    id: 6,
                    name: "Steluta",
                    profile: "Hair & Makeup",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
                {
                    id: 7,
                    name: "Malisa",
                    profile: "Hair & Makeup",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
                {
                    id: 8,
                    name: "Julia",
                    profile: "Beauty",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
                {
                    id: 9,
                    name: "Mike",
                    profile: "Beauty",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
                {
                    id: 10,
                    name: "Jemma",
                    profile: "Fitness",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
                {
                    id: 11,
                    name: "John",
                    profile: "Fitness",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
                {
                    id: 12,
                    name: "Prinish",
                    profile: "Fitness",
                    reveal: false,
                    // imgSrc: "https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?auto=compress&cs=tinysrgb&w=800",
                    bio: "Whether you're looking for a classic hairstyle or a complete transformation, Steluta has the skills and expertise to bring your vision to life.",
                    redirect: "/"
                },
            ]
        }
    },
    computed: {
        filteredProfiles() {
            if (this.searchInput == "Our Team") {
                return this.people;
            }
            return this.people.filter(person => {
                return person.profile.toLowerCase().includes(this.searchInput.toLowerCase());
            });
        }
    }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
