import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c(VAppBar,{attrs:{"color":"#F4F2E8","elevation":"2"}},[_c(VAppBarNavIcon,{on:{"click":function($event){_vm.drawer = true}}}),_c(VToolbarTitle,{staticStyle:{"padding":"0"}},[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{attrs:{"width":"140px","src":require("@/assets/logo-very-small.jpg")}})],1)],1),_c(VSpacer),_c(VBtn,{staticClass:"white--text",attrs:{"href":"/booking","link":"","elevation":"0","rounded":"","small":"","color":"#333"}},[_c('strong',[_vm._v("Book Now")])])],1),_c(VNavigationDrawer,{staticClass:"fix-index",attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,{attrs:{"nav":"","dense":""}},[_c(VListItemGroup,{model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[_c('router-link',{attrs:{"to":"/"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Home")])],1)],1),_c('router-link',{attrs:{"to":"/about"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("About")])],1)],1),_c('router-link',{attrs:{"to":"/massage"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Massage")])],1)],1),_c('router-link',{attrs:{"to":"/beauty"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Beauty")])],1)],1),_c('router-link',{attrs:{"to":"/makeup"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Hair & Makeup")])],1)],1),_c('router-link',{attrs:{"to":"/fitness-yoga"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Fitness")])],1)],1),_c('router-link',{attrs:{"to":"/therapists"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Our Team")])],1)],1),_c('router-link',{attrs:{"to":"/contact"}},[_c(VListItem,[_c(VListItemTitle,[_vm._v("Contact")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }