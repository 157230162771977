// 

const state = {
    locations: [
        {
            "hotel": "11 CADOGAN GARDENS HOTEL",
            "postcode": "SW3 2RJ"
        },
        {
            "hotel": "130 Queen's Gate Apartments",
            "postcode": "SW7 5LE"
        },
        {
            "hotel": "Hotel 41",
            "postcode": "SW1W 0PS"
        },
        {
            "hotel": "44 Curzon St",
            "postcode": "WI7 7RF"
        },
        {
            "hotel": "45 PARK LANE",
            "postcode": "W1K 1PN"
        },
        {
            "hotel": "Grand Residences by Marriott - Mayfair-London",
            "postcode": "W1K 7EB"
        },
        {
            "hotel": "Ace Hotel London",
            "postcode": "E1 6JQ"
        },
        {
            "hotel": "Alfred Dunhill",
            "postcode": "W1K 3DJ"
        },
        {
            "hotel": "Amba Hotel Charing Cross",
            "postcode": "WC2N 5HX"
        },
        {
            "hotel": "Apex City of London Hotel",
            "postcode": "EC3N 4AX"
        },
        {
            "hotel": "Apex London Wall Hotel",
            "postcode": "EC2R 7NJ"
        },
        {
            "hotel": "Apex Temple Court Hotel",
            "postcode": "EC4Y 1LL"
        },
        {
            "hotel": "THE ATHENAEUM HOTEL & RESIDENCES",
            "postcode": "W1J 7BJ"
        },
        {
            "hotel": "Baglioni Hotel - London",
            "postcode": "SW7 5BB"
        },
        {
            "hotel": "The Beaufort Hotel",
            "postcode": "SW3 1PP"
        },
        {
            "hotel": "Beaufort House Apartments",
            "postcode": "SW3 1PN"
        },
        {
            "hotel": "THE BEAUMONT",
            "postcode": "W1K 6TF"
        },
        {
            "hotel": "THE HARI",
            "postcode": "SW1X 8HQ"
        },
        {
            "hotel": "THE BERKELEY",
            "postcode": "SW1X 7RB"
        },
        {
            "hotel": "THE BLOOMSBURY",
            "postcode": "WC1B 3NN"
        },
        {
            "hotel": "Boardwalk Place",
            "postcode": "E14 5SE"
        },
        {
            "hotel": "The Bromptons",
            "postcode": "SW3 6RS"
        },
        {
            "hotel": "Browns Hotel",
            "postcode": "W1S 4BP"
        },
        {
            "hotel": "Bulgari Hotel London",
            "postcode": "SW7 1DW"
        },
        {
            "hotel": "BELMOND CADOGAN HOTEL, LONDON",
            "postcode": "SW1X 9SG"
        },
        {
            "hotel": "The Capital Hotel",
            "postcode": "SW3 1AT"
        },
        {
            "hotel": "JUMEIRAH CARLTON TOWER",
            "postcode": "SW1X 9PY"
        },
        {
            "hotel": "THE CAVENDISH LONDON",
            "postcode": "SW1Y 6JF"
        },
        {
            "hotel": "AMBA HOTEL MARBLE ARCH",
            "postcode": "W1H 7EH"
        },
        {
            "hotel": "Charlotte Street Hotel",
            "postcode": "W1T 1RJ"
        },
        {
            "hotel": "Chelsea Cloisters",
            "postcode": "SW3 3DW"
        },
        {
            "hotel": "Chesham Court",
            "postcode": "SW1X 8HJ"
        },
        {
            "hotel": "THE CHESTERFIELD MAYFAIR",
            "postcode": "W1J 5EB"
        },
        {
            "hotel": "CHEVAL CALICO HOUSE",
            "postcode": "EC4M 9DT"
        },
        {
            "hotel": "CHEVAL GLOUCESTER PARK",
            "postcode": "SW7 4LL"
        },
        {
            "hotel": "CHEVAL HYDE PARK GATE",
            "postcode": "SW7 5EW"
        },
        {
            "hotel": "CHEVAL KNIGHTSBRIDGE",
            "postcode": "SW7 1EW"
        },
        {
            "hotel": "CHEVAL THREE QUAYS",
            "postcode": "EC3R 5BT"
        },
        {
            "hotel": "CHILTERN FIREHOUSE",
            "postcode": "W1U 7PA"
        },
        {
            "hotel": "HYATT REGENCY LONDON - THE CHURCHILL",
            "postcode": "W1H 7BH"
        },
        {
            "hotel": "Citadines Barbican London",
            "postcode": "EC1M 7AH"
        },
        {
            "hotel": "Citadines Trafalgar Square London",
            "postcode": "WC2N 5EA"
        },
        {
            "hotel": "Claverley Court",
            "postcode": "SW3 1PS"
        },
        {
            "hotel": "Club Quarters Hotel, St Paul's",
            "postcode": "EC4M 7DR"
        },
        {
            "hotel": "Club Quarters Hotel, Trafalgar Square",
            "postcode": "WC2N 5BY"
        },
        {
            "hotel": "Commodore Hotel",
            "postcode": "W2 3NA"
        },
        {
            "hotel": "THE CONNAUGHT HOTEL",
            "postcode": "W1K 2AL"
        },
        {
            "hotel": "CONRAD LONDON ST. JAMES",
            "postcode": "SW1H 0BH"
        },
        {
            "hotel": "CORINTHIA HOTEL LONDON",
            "postcode": "SW1A 2BD"
        },
        {
            "hotel": "Courthouse Hotel- Shoreditch",
            "postcode": "EC1V 9LL"
        },
        {
            "hotel": "COVENT GARDEN HOTEL",
            "postcode": "WC2H 9LF"
        },
        {
            "hotel": "CROWNE PLAZA LONDON - THE CITY",
            "postcode": "EC4V 6DB"
        },
        {
            "hotel": "Crowne Plaza London - Kensington",
            "postcode": "SW7 4ER"
        },
        {
            "hotel": "Danubius Hotel Regents Park",
            "postcode": "NW8 TJT"
        },
        {
            "hotel": "DEAN STREET TOWNHOUSE",
            "postcode": "W1D 3SE"
        },
        {
            "hotel": "THE DORCHESTER PARK LANE",
            "postcode": "W1K 1QA"
        },
        {
            "hotel": "DORSET SQUARE HOTEL",
            "postcode": "NW1 6QN"
        },
        {
            "hotel": "DoubleTree by Hilton Hotel London - Victoria",
            "postcode": "SW1V 1QA"
        },
        {
            "hotel": "DoubleTree by Hilton Hotel London - Westminster",
            "postcode": "SW1P 4DD"
        },
        {
            "hotel": "DoubleTree by Hilton Hotel London - Marble Arch",
            "postcode": "W1H 7BY"
        },
        {
            "hotel": "DoubleTree by Hilton Hotel London - Tower of London",
            "postcode": "EC3N 4AF"
        },
        {
            "hotel": "Draycott Hotel by Mantis",
            "postcode": "SW3 2RP"
        },
        {
            "hotel": "DUKES LONDON",
            "postcode": "SW1A 1NY"
        },
        {
            "hotel": "Durrants Hotel London",
            "postcode": "W1H 5BJ"
        },
        {
            "hotel": "Eccleston Square Hotel",
            "postcode": "SW1V 1PB"
        },
        {
            "hotel": "THE EGERTON HOUSE HOTEL",
            "postcode": "SW3 2BX"
        },
        {
            "hotel": "54 Queen's Gate",
            "postcode": "SW7 5JW"
        },
        {
            "hotel": "FLEMINGS MAYFAIR",
            "postcode": "W1J 7BH"
        },
        {
            "hotel": "Fountain House",
            "postcode": "W2 3PF"
        },
        {
            "hotel": "Four Seasons Hotel London at Park Lane",
            "postcode": "W1J 7DR"
        },
        {
            "hotel": "Fraser Suites Queens Gate, London",
            "postcode": "SW7 5RR"
        },
        {
            "hotel": "Fraser Residence Blackfriars, London",
            "postcode": "EC4V 5EU"
        },
        {
            "hotel": "Fraser Residence City",
            "postcode": "EC3R 8DT"
        },
        {
            "hotel": "Fraser Suites Queens Gate, London",
            "postcode": "SW7 5RR"
        },
        {
            "hotel": "Grange Blooms Hotel",
            "postcode": "WC1B 5BJ"
        },
        {
            "hotel": "Leonardo Royal Hotel London City",
            "postcode": "EC3N 2BD"
        },
        {
            "hotel": "Jurys Inn London Holborn",
            "postcode": "WC1B 4AR"
        },
        {
            "hotel": "Grange Rochester Hotel",
            "postcode": "SW1P 2PA"
        },
        {
            "hotel": "Leonardo Royal Hotel London St Paul's",
            "postcode": "EC4V 5AJ"
        },
        {
            "hotel": "Leonardo Royal Hotel London Tower Bridge",
            "postcode": "E1 8GP"
        },
        {
            "hotel": "Grange White Hall Hotel",
            "postcode": "WC1B 5BU"
        },
        {
            "hotel": "JW Marriott Grosvenor House London",
            "postcode": "W1K 7TN"
        },
        {
            "hotel": "THE GROS HOTEL",
            "postcode": "SW1W 0SJ"
        },
        {
            "hotel": "H10 London Waterloo",
            "postcode": "SE1 8RQ"
        },
        {
            "hotel": "COMO THE HALKIN, LONDON",
            "postcode": "SW1X 7DJ"
        },
        {
            "hotel": "Ham Yard Hotel",
            "postcode": "W1D 7DT"
        },
        {
            "hotel": "HAYMARKET HOTEL",
            "postcode": "SW1Y 4HX"
        },
        {
            "hotel": "Hazlitt's",
            "postcode": "W1D 3JA"
        },
        {
            "hotel": "Hilton London Canary Wharf",
            "postcode": "E14 9SH"
        },
        {
            "hotel": "Hilton London Euston",
            "postcode": "WC1H DHT"
        },
        {
            "hotel": "Hilton London Hyde Park",
            "postcode": "W2 4RJ"
        },
        {
            "hotel": "HILTON LONDON KENSINGTON",
            "postcode": "W11 4UL"
        },
        {
            "hotel": "HILTON LONDON BANKSIDE",
            "postcode": "SE1 0UG"
        },
        {
            "hotel": "Hilton London Olympia",
            "postcode": "W14 8NL"
        },
        {
            "hotel": "HILTON LONDON METROPOLE",
            "postcode": "W2 1JU"
        },
        {
            "hotel": "Hilton London Paddington",
            "postcode": "W2 1EE"
        },
        {
            "hotel": "LONDON HILTON ON PARK LANE",
            "postcode": "W1K 1BE"
        },
        {
            "hotel": "The Trafalgar St. James London, Curio Collection by Hilton",
            "postcode": "W1A 2TS"
        },
        {
            "hotel": "The Waldorf Hilton, London",
            "postcode": "WC2B 4DD"
        },
        {
            "hotel": "Holiday Inn London Bloomsbury",
            "postcode": "WC1N 1HT"
        },
        {
            "hotel": "Crowne Plaza London - Kings Cross",
            "postcode": "WC1X 9HX"
        },
        {
            "hotel": "Holiday Inn London Mayfair",
            "postcode": "W1J 8NE"
        },
        {
            "hotel": "HOME HOUSE LONDON",
            "postcode": "W1H 6LW"
        },
        {
            "hotel": "Kimpton Fitzroy London Hotel",
            "postcode": "WC1B 5BE"
        },
        {
            "hotel": "St. Ermin's Hotel, Autograph Collection",
            "postcode": "SW1H 0QW"
        },
        {
            "hotel": "Park Grand Lancaster Gate Hotel",
            "postcode": "W2 3DU"
        },
        {
            "hotel": "Hyde Park Residence Ltd",
            "postcode": "W1K 1NA"
        },
        {
            "hotel": "INTERCONTINENTAL LONDON PARK LANE",
            "postcode": "W1J 7QY"
        },
        {
            "hotel": "K WEST HOTEL & SPA",
            "postcode": "W14 0AX"
        },
        {
            "hotel": "The Kensington Hotel",
            "postcode": "SW7 5LP"
        },
        {
            "hotel": "THE KNIGHTSBRIDGE HOTEL",
            "postcode": "SW3 1PX"
        },
        {
            "hotel": "Royal Lancaster London",
            "postcode": "W2 2TY"
        },
        {
            "hotel": "THE LANDMARK LONDON",
            "postcode": "NW1 6JQ"
        },
        {
            "hotel": "THE LANESBOROUGH",
            "postcode": "SW1X 7TA"
        },
        {
            "hotel": "THE LANGHAM LONDON",
            "postcode": "W1B 1JA"
        },
        {
            "hotel": "Langorf Hotel",
            "postcode": "NW3 6AG"
        },
        {
            "hotel": "London Bridge Hotel",
            "postcode": "SE1 9SG"
        },
        {
            "hotel": "THE LONDON EDITION",
            "postcode": "W1T 3NP"
        },
        {
            "hotel": "Jumeirah Lowndes Hotel",
            "postcode": "SW1X 9ES"
        },
        {
            "hotel": "ME LONDON",
            "postcode": "WC2R 1HA"
        },
        {
            "hotel": "MANDARIN ORIENTAL HYDE PARK, LONDON",
            "postcode": "SW1X 7LA"
        },
        {
            "hotel": "THE MANDEVILLE HOTEL",
            "postcode": "W1U 2BE"
        },
        {
            "hotel": "LONDON MARRIOTT HOTEL COUNTY HALL",
            "postcode": "SE1 7PB"
        },
        {
            "hotel": "London Marriott Hotel Grosvenor Square",
            "postcode": "W1K 6JP"
        },
        {
            "hotel": "London MarriottKensington",
            "postcode": "SW5 0TH"
        },
        {
            "hotel": "London Marriott Hotel Marble Arch",
            "postcode": "W1H 5DN"
        },
        {
            "hotel": "London Marriott Hotel Park Lane",
            "postcode": "W1K 7AA"
        },
        {
            "hotel": "London Marriott Hotel Regents Park",
            "postcode": "NW3 3ST"
        },
        {
            "hotel": "London Marriott Hotel West India Quay",
            "postcode": "E14 4ED"
        },
        {
            "hotel": "Mayfair House - Serviced Apartments London",
            "postcode": "W1J 7JH"
        },
        {
            "hotel": "The Megaro Hotel",
            "postcode": "WC1H 8AB"
        },
        {
            "hotel": "MeliÃ¡ White House",
            "postcode": "NW1 3UP"
        },
        {
            "hotel": "COMO Metropolitan London",
            "postcode": "W1K 1LB"
        },
        {
            "hotel": "THE MILESTONE HOTEL",
            "postcode": "W8 5DL"
        },
        {
            "hotel": "Millennium Gloucester Hotel London",
            "postcode": "SW7 4LH"
        },
        {
            "hotel": "Millennium & Copthorne Hotels At Chelsea Football Club",
            "postcode": "SW6 1HS"
        },
        {
            "hotel": "The Baileys Hotel London",
            "postcode": "SW7 4QH"
        },
        {
            "hotel": "MILLENNIUM HOTEL LONDON KNIGHTSBRIDGE",
            "postcode": "SW1X 9NU"
        },
        {
            "hotel": "Sea Containers London",
            "postcode": "SE1 9PD"
        },
        {
            "hotel": "MY CHELSEA",
            "postcode": "SW3 3QX"
        },
        {
            "hotel": "",
            "postcode": "SW5 0PG"
        },
        {
            "hotel": "THE NADLER SOHO HOTEL",
            "postcode": "W1D 3BR"
        },
        {
            "hotel": "No.1 The Mansions by Mansley",
            "postcode": ""
        },
        {
            "hotel": "Novotel London Waterloo Hotel",
            "postcode": "SE1 7LS"
        },
        {
            "hotel": "NUMBER SIXTEEN",
            "postcode": "SW7 3EG"
        },
        {
            "hotel": "ONE KENSINGTON GARDENS",
            "postcode": "W8 5PE"
        },
        {
            "hotel": "Park Plaza County Hall",
            "postcode": "SE1 7RY"
        },
        {
            "hotel": "Park Plaza Victoria London",
            "postcode": "SW1V 1EQ"
        },
        {
            "hotel": "PARK PLAZA WESTMINSTER BRIDGE HOTEL",
            "postcode": "SE1 7UT"
        },
        {
            "hotel": "THE PARK TOWER KNIGHTSBRIDGE, A LUXURY COLLECTION HOTEL, LONDON",
            "postcode": "SW1X 7RN"
        },
        {
            "hotel": "The Pelham London - Starhotels Collezione",
            "postcode": "SW7 2LA"
        },
        {
            "hotel": "Pestana Chelsea Bridge Hotel & Spa",
            "postcode": "SW8 4AE"
        },
        {
            "hotel": "Cheval Phoenix House",
            "postcode": "SW1X 9AE"
        },
        {
            "hotel": "President Hotel",
            "postcode": "WC1N 1DB"
        },
        {
            "hotel": "Radisson Blu Edwardian New Providence Wharf",
            "postcode": "E14 9JB"
        },
        {
            "hotel": "Radisson Blu Portman Hotel, London",
            "postcode": "W1H 7BG"
        },
        {
            "hotel": "Radisson Blu Edwardian, Kenilworth",
            "postcode": "WC1B 3LB"
        },
        {
            "hotel": "Radisson Blu Edwardian Bloomsbury Street Hotel",
            "postcode": "WC1B 3QD"
        },
        {
            "hotel": "Radisson Blu Edwardian, Hampshire",
            "postcode": "WC2H 7LH"
        },
        {
            "hotel": "Radisson Blu Edwardian, Vanderbilt",
            "postcode": "SW7 5BT"
        },
        {
            "hotel": "Radisson Blu Edwardian, Mercer Street",
            "postcode": "WC2H 9HD"
        },
        {
            "hotel": "Hotel Rafayel",
            "postcode": "SW11 3RF"
        },
        {
            "hotel": "100 Queen's Gate Hotel London, Curio Collection by Hilton",
            "postcode": "SW7 5AG"
        },
        {
            "hotel": "THE RITZ LONDON",
            "postcode": "W1J 9BR"
        },
        {
            "hotel": "PARK PLAZA LONDON RIVERBANK",
            "postcode": "SE1 7TJ"
        },
        {
            "hotel": "ROSEWOOD LONDON",
            "postcode": "WC1V 7EN"
        },
        {
            "hotel": "ROYAL GARDEN HOTEL",
            "postcode": "W8 4PT"
        },
        {
            "hotel": "HOTEL ROYAL NATIONAL",
            "postcode": "WC1H 0DG"
        },
        {
            "hotel": "Rydges Kensington London",
            "postcode": "SW7 4PE"
        },
        {
            "hotel": "San Domenico House Hotel, Chelsea and Knightsbridge London",
            "postcode": "SW3 2SH"
        },
        {
            "hotel": "SANCTUM SOHO HOTEL",
            "postcode": "W1B 5NF"
        },
        {
            "hotel": "SANDERSON LONDON",
            "postcode": "W1T 3NG"
        },
        {
            "hotel": "SHANGRI-LA HOTEL AT THE SHARD LONDON",
            "postcode": "SE1 3UD"
        },
        {
            "hotel": "DORSETT SHEPHERDS BUSH, LONDON",
            "postcode": "W12 8QE"
        },
        {
            "hotel": "Sheraton Grand London Park Lane",
            "postcode": "W1J 7BX"
        },
        {
            "hotel": "PARK PLAZA SHERLOCK HOLMES LONDON",
            "postcode": "W1U 6LJ"
        },
        {
            "hotel": "SYDNEY HOUSE CHELSEA",
            "postcode": "SW3 6PU"
        },
        {
            "hotel": "SLOANE SQUARE HOTEL",
            "postcode": "SW1W 8EG"
        },
        {
            "hotel": "SOFITEL LONDON ST JAMES",
            "postcode": "SW1Y 4AN"
        },
        {
            "hotel": "THE SOHO HOTEL",
            "postcode": "W1D 3DH"
        },
        {
            "hotel": "SOUTH PLACE HOTEL",
            "postcode": "EC2M 2AF"
        },
        {
            "hotel": "StJamesHotelandClub",
            "postcode": "SW1A 1LP"
        },
        {
            "hotel": "ST. PANCRAS RENAISSANCE HOTEL LONDON",
            "postcode": "NW1 2AR"
        },
        {
            "hotel": "STRAND PALACE HOTEL",
            "postcode": "WC2R 0JJ"
        },
        {
            "hotel": "TAJ 51 BUCKINGHAM GATE SUITES AND RESIDENCES",
            "postcode": "SW1E 6AF"
        },
        {
            "hotel": "NO. TEN MANCHESTER STREET HOTEL",
            "postcode": "W1U 4DG"
        },
        {
            "hotel": "THE AMPERSAND HOTEL",
            "postcode": "SW7 3ER"
        },
        {
            "hotel": "THE BENTLEY HOTEL LONDON",
            "postcode": "SW7 4JX"
        },
        {
            "hotel": "Hard Rock Hotel London",
            "postcode": "W1H 7DL"
        },
        {
            "hotel": "THE EXHIBITIONIST HOTEL",
            "postcode": "SW7 2EA"
        },
        {
            "hotel": "THE GORE HOTEL",
            "postcode": "SW7 5EX"
        },
        {
            "hotel": "THE GORING HOTEL",
            "postcode": "SW1W 0JW"
        },
        {
            "hotel": "RADISSON BLU EDWARDIAN GRAFTON",
            "postcode": "W1T 5AY"
        },
        {
            "hotel": "THE HARRINGTON COLLECTION",
            "postcode": "SW7 4JJ"
        },
        {
            "hotel": "THE KNIGHTSBRIDGE APARTMENTS",
            "postcode": "SW7 1RH"
        },
        {
            "hotel": "Lancaster Gate Hyde Park Apartments",
            "postcode": "W2 3NH"
        },
        {
            "hotel": "Landmark Apartments",
            "postcode": "E14 9AB"
        },
        {
            "hotel": "THE LEONARD HOTEL",
            "postcode": "W1H 7JW"
        },
        {
            "hotel": "The Marylebone Hotel",
            "postcode": "W1G 8DN"
        },
        {
            "hotel": "The May Fair, A Radisson Collection Hotel",
            "postcode": "W1J 8LT"
        },
        {
            "hotel": "THE MARBLE ARCH BY MONTCALM LONDON",
            "postcode": "W1H 7TN"
        },
        {
            "hotel": "The Rembrandt Hotel",
            "postcode": "SW7 2RS"
        },
        {
            "hotel": "THE ROYAL AUTOMOBILE CLUB",
            "postcode": "SW1Y 5HS"
        },
        {
            "hotel": "THE ROYAL HORSEGUARDS",
            "postcode": "SW1A 2EJ"
        },
        {
            "hotel": "THE ROYAL PARK HOTEL",
            "postcode": "W2 3UL"
        },
        {
            "hotel": "The Rubens at the Palace",
            "postcode": "SW1W 0PS"
        },
        {
            "hotel": "Hotel The Savoy",
            "postcode": "WC2R 0EU"
        },
        {
            "hotel": "THE TRAFALGAR HOTEL",
            "postcode": "SW1A 2TS"
        },
        {
            "hotel": "THE WASHINGTON MAYFAIR HOTEL",
            "postcode": "W1J 5HE"
        },
        {
            "hotel": "The Wellesley Knightsbridge, a Luxury Collection Hotel, London",
            "postcode": "SW1X 7LY"
        },
        {
            "hotel": "THE ZETTER TOWNHOUSE CLERKENWELL",
            "postcode": "EC1V 4JJ"
        },
        {
            "hotel": "THISTLE PICCADILLY",
            "postcode": "W1D 6BZ"
        },
        {
            "hotel": "CHEVAL THORNEY COURT",
            "postcode": "W8 5NJ"
        },
        {
            "hotel": "THREADNEEDLES, AUTOGRAPH COLLECTION",
            "postcode": "EC2R 8AY"
        },
        {
            "hotel": "THE TOWER HOTEL",
            "postcode": "E1W 1LD"
        },
        {
            "hotel": "ST GEORGE WHARF TOWER",
            "postcode": "SW8 2DU"
        },
        {
            "hotel": "W LONDON - LEICESTER SQUARE",
            "postcode": "W1D 6QF"
        },
        {
            "hotel": "The Westbury Mayfair, A Luxury Collection Hotel, London",
            "postcode": "W1S 2YF"
        },
        {
            "hotel": "THE ZETTER HOTEL",
            "postcode": "EC1M 5RJ"
        },
        {
            "hotel": "CAROL JOY LONDON",
            "postcode": "W1K 1QA"
        },
        {
            "hotel": "Grosvenor House Suites by Jumeirah Living",
            "postcode": "W1K 7TN"
        },
        {
            "hotel": "The Franklin London - Starhotels Collezione",
            "postcode": "SW3 2DB"
        },
        {
            "hotel": "Claridges",
            "postcode": "W1K 4HR"
        },
        {
            "hotel": "HOTEL CAFE ROYAL",
            "postcode": "W1B 4DY"
        },
        {
            "hotel": "InterContinental London - The O2",
            "postcode": "SE10 0TW"
        },
        {
            "hotel": "Four Seasons Hotel London at Ten Trinity Square",
            "postcode": "EC3N 4AJ"
        },
        {
            "hotel": "Four Seasons Hotel London at Ten Trinity Square",
            "postcode": "EC3N 4AJ"
        },
        {
            "hotel": "",
            "postcode": "SW1X 8EL"
        },
        {
            "hotel": "Leman Locke",
            "postcode": "E1 8EN"
        },
        {
            "hotel": "Hotel Novotel London Canary Wharf",
            "postcode": "E14 9TP"
        },
        {
            "hotel": "Le Meridien Piccadilly",
            "postcode": "W1J 0BH"
        },
        {
            "hotel": "Blakes Hotel London",
            "postcode": "SW7 3PF"
        },
        {
            "hotel": "St. JamesCourt, A Taj Hotel, London",
            "postcode": "SW1E 6AF"
        },
        {
            "hotel": "One Aldwych Hotel",
            "postcode": "WC2B 4BZ"
        },
        {
            "hotel": "The Prince Akatoki London",
            "postcode": "W1H 7FD"
        },
        {
            "hotel": "St Giles London - A St Giles Hotel",
            "postcode": "WC1B 3GH"
        },
        {
            "hotel": "Park Plaza London Waterloo",
            "postcode": "SE1 7DP"
        },
        {
            "hotel": "Holiday Inn KensingtonForum",
            "postcode": "SW7 4DN"
        },
        {
            "hotel": "",
            "postcode": "W1B1QB"
        },
        {
            "hotel": "The Arts Club",
            "postcode": "W1S 4NP"
        },
        {
            "hotel": "",
            "postcode": "SW8 2LW"
        },
        {
            "hotel": "The Ned",
            "postcode": "EC2R 8AJ"
        },
        {
            "hotel": "Nobu Hotel Shoreditch",
            "postcode": "EC2A 4BH"
        },
        {
            "hotel": "Corus Hotel Hyde Park",
            "postcode": "W2 3LG"
        },
        {
            "hotel": "THE ROOKERY",
            "postcode": "EC1M 6DS"
        },
        {
            "hotel": "ASHBURN HOTEL",
            "postcode": "SW7 4DP"
        },
        {
            "hotel": "Adria",
            "postcode": "SW7 5AB"
        },
        {
            "hotel": "",
            "postcode": "SW7 5AG"
        },
        {
            "hotel": "Arlington House Apartments",
            "postcode": "SW1A 1RL"
        },
        {
            "hotel": "Hotel Victory House London Leicester Square MGallery by Sof",
            "postcode": "WC2H 7BZ"
        },
        {
            "hotel": "",
            "postcode": "SW7 4ER"
        },
        {
            "hotel": "",
            "postcode": "EC4Y 1LL"
        },
        {
            "hotel": "Millennium Glouster Hotel",
            "postcode": "SW7 4LH"
        },
        {
            "hotel": "",
            "postcode": "EC1Y 4SA"
        },
        {
            "hotel": "DoubleTree by Hilton Hotel London - Hyde Park",
            "postcode": "W2 4 RT"
        },
        {
            "hotel": "Crawford Suites Serviced Apartments",
            "postcode": "W1H 4LA"
        },
        {
            "hotel": "The Montcalm at The Brewery London City",
            "postcode": "EC1Y 4SA"
        },
        {
            "hotel": "Courthouse Hotel",
            "postcode": "W1F7HL"
        },
        {
            "hotel": "Clarges Mayfair - Luxury Apartments",
            "postcode": "W1J 8AS"
        },
        {
            "hotel": "Barratt London",
            "postcode": "EC3R 6EA"
        },
        {
            "hotel": "The Mandrake Hotel",
            "postcode": "W1T 1PG"
        },
        {
            "hotel": "Cheval Harrington Court",
            "postcode": "SW7 3ES"
        },
        {
            "hotel": "citizenM Tower of London hotel",
            "postcode": "EC3N 4DJ"
        },
        {
            "hotel": "Radisson Blu Edwardian Berkshire",
            "postcode": "W1C 1BY"
        },
        {
            "hotel": "",
            "postcode": "SW6 2QF"
        },
        {
            "hotel": "Loscar London",
            "postcode": "WC1B 4AA"
        },
        {
            "hotel": "Club Quarters Hotel, Lincoln's Inn Fields - Holborn",
            "postcode": "WC2A 3JW"
        },
        {
            "hotel": "The Hoxton, Holborn",
            "postcode": "WC1V 7BD"
        },
        {
            "hotel": "Henrietta Hotel",
            "postcode": "WC2E 8QG"
        },
        {
            "hotel": "SACO Covent Garden - St Martins",
            "postcode": "WC2E 9AB"
        },
        {
            "hotel": "Hotel Indigo London - 1 Leicester Square",
            "postcode": "WC2H 7NA"
        },
        {
            "hotel": "",
            "postcode": "SE10 0TW"
        },
        {
            "hotel": "Bankside Hotel, Autograph Collection",
            "postcode": "SE1 9JU"
        },
        {
            "hotel": "Great Northern Hotel, a Tribute Portfolio Hotel, London",
            "postcode": "N1C 4TB"
        },
        {
            "hotel": "The Montcalm Royal London House",
            "postcode": "EC2A 1DX"
        },
        {
            "hotel": "CitizenM Shoreditch",
            "postcode": "EC2A 3ET"
        },
        {
            "hotel": "Crowne Plaza London - Battersea",
            "postcode": "SW113BE"
        },
        {
            "hotel": "",
            "postcode": "W1J9BR"
        },
        {
            "hotel": "The Curtain Hotel",
            "postcode": "EC2A3PT"
        },
        {
            "hotel": "The Boundary Project",
            "postcode": "E27DD"
        },
        {
            "hotel": "DoubleTree by Hilton Hotel London - Islington",
            "postcode": "N1 9LA"
        },
        {
            "hotel": "Devonshire Club",
            "postcode": "EC2M4YD"
        },
        {
            "hotel": "The Academy",
            "postcode": ""
        },
        {
            "hotel": "DoubleTree by Hilton hotel London West End",
            "postcode": "WC1B4BH"
        },
        {
            "hotel": "New Road Hotel",
            "postcode": "E11HJ"
        },
        {
            "hotel": "The East London",
            "postcode": "E29LH"
        },
        {
            "hotel": "Batty Langley's",
            "postcode": "E16BX"
        },
        {
            "hotel": "Vintry and Mercer",
            "postcode": "EC4V 2AU"
        },
        {
            "hotel": "Dorsett City, London",
            "postcode": "EC3N 1AH"
        },
        {
            "hotel": "Aldgate Place",
            "postcode": ""
        },
        {
            "hotel": "",
            "postcode": "SW10 0XB"
        },
        {
            "hotel": "79-83 Great Portland St",
            "postcode": "W1W 7LS"
        },
        {
            "hotel": "The Dixon Tower Bridge",
            "postcode": "SE12JX"
        },
        {
            "hotel": "The Windermere hotel",
            "postcode": "SW1V 4JE"
        },
        {
            "hotel": "Radisson Blu Edwardian Sussex",
            "postcode": "W1H 6PA"
        },
        {
            "hotel": "The Sloane Club",
            "postcode": "SW1W8BS"
        },
        {
            "hotel": "Residence Inn Marriott",
            "postcode": "SE14PN"
        },
        {
            "hotel": "Lincoln Plaza London",
            "postcode": "E149BD"
        },
        {
            "hotel": "The Bermondsey Square Hotel",
            "postcode": "SE13UN"
        },
        {
            "hotel": "Native Mayfair",
            "postcode": "W1K6LL"
        },
        {
            "hotel": "Karma Sanctum Hotel",
            "postcode": "W1B5NF"
        },
        {
            "hotel": "Novotel London West Hotel",
            "postcode": "W68DR"
        },
        {
            "hotel": "Lord Milner",
            "postcode": "SW1W9QU"
        },
        {
            "hotel": "Tulip Boutique Hotel London",
            "postcode": "W1277JS"
        },
        {
            "hotel": "St. Paul's Hotel",
            "postcode": "W14OQL"
        },
        {
            "hotel": "Staybridge Suites London Vauxhall",
            "postcode": "SE115EL"
        },
        {
            "hotel": "Nell Gwynn Houwse",
            "postcode": "SW33AX"
        },
        {
            "hotel": "",
            "postcode": "SW7 4ES"
        },
        {
            "hotel": "Lodha Group",
            "postcode": "SW1Y 4JU"
        },
        {
            "hotel": "",
            "postcode": "GU29 0AY"
        },
        {
            "hotel": "",
            "postcode": "WC1A 1DB"
        },
        {
            "hotel": "The Nadler Hotel",
            "postcode": "WC2R 0PZ"
        },
        {
            "hotel": "",
            "postcode": "UB7 0DG"
        },
        {
            "hotel": "The Atlas Building",
            "postcode": "N1 6AD"
        },
        {
            "hotel": "",
            "postcode": "N1 6AD"
        },
        {
            "hotel": "The Standard London",
            "postcode": "WC1H 8EG"
        },
        {
            "hotel": "Copthorne Tara Hotel",
            "postcode": "W8 5SY"
        },
        {
            "hotel": "",
            "postcode": "SW1E 6JP"
        },
        {
            "hotel": "The Stratford Hotel London",
            "postcode": "E20 1FD"
        },
        {
            "hotel": "",
            "postcode": "W1K1PN"
        },
        {
            "hotel": "",
            "postcode": "W12 8qe"
        },
        {
            "hotel": "",
            "postcode": "W12 8qe"
        },
        {
            "hotel": "",
            "postcode": "w12 8qe"
        },
        {
            "hotel": "THE MANSION",
            "postcode": "W1U1DB"
        },
        {
            "hotel": "",
            "postcode": "SW7 5LE"
        },
        {
            "hotel": "The Mansion, Marylebone",
            "postcode": "W1U 1DB"
        },
        {
            "hotel": "The Biltmore Mayfair, LXR Hotels & Resorts",
            "postcode": "W1K 2HP"
        },
        {
            "hotel": "Hotel Indigo London - Tower Hill",
            "postcode": "EC3N 1LS"
        },
        {
            "hotel": "EC2A 3HU",
            "postcode": "EC2A 3HU"
        },
        {
            "hotel": "",
            "postcode": "EC2A 3HU"
        },
        {
            "hotel": "",
            "postcode": "EC2A 3HU"
        },
        {
            "hotel": "",
            "postcode": "Nw1 4RP"
        },
        {
            "hotel": "The Grove",
            "postcode": "WD3 4TG"
        },
        {
            "hotel": "Holiday Inn Express",
            "postcode": "SW8 1RN"
        },
        {
            "hotel": "Page8",
            "postcode": "WC2N 4JH"
        }
    ]
}


const mutations = {

}

const actions = {

}

const getters = {
    getLocations(state) {
        return state.locations;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
