import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main mt-4 py-4"},[_c(VContainer,[_c('h1',{staticClass:"my-4 text-center"},[_vm._v("Meet The Founders")]),_c(VRow,[_c(VCol,{attrs:{"color":"transparent","cols":"12","sm":"12","md":"6","lg":"6"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"300px","color":"#fefefe","elevation":"0"}},[_c(VImg,{attrs:{"height":"250px","src":require("@/assets/maninder.jpg")}}),_c(VCardText,[_c('div',{staticClass:"arrange"},[_c('p',[_c('strong',[_vm._v("Maninder Pal Singh")]),_c('br'),_vm._v(" Co-Founder ")]),_c(VBtn,{attrs:{"href":"https://www.linkedin.com/in/maninder-singh-16b4066b","link":"","icon":"","rounded":"","outlined":""}},[_c(VIcon,[_vm._v("mdi-linkedin")])],1)],1)])],1)],1),_c(VCol,{attrs:{"color":"transparent","cols":"12","sm":"12","md":"6","lg":"6"}},[_c(VCard,{staticClass:"mx-auto",attrs:{"width":"300px","color":"#fefefe","elevation":"0"}},[_c(VImg,{attrs:{"height":"250px","cover":"","src":require("@/assets/hassan.jpg")}}),_c(VCardText,[_c('div',{staticClass:"arrange"},[_c('p',[_c('strong',[_vm._v("Hassan Abbas")]),_c('br'),_vm._v(" Co-Founder ")]),_c(VBtn,{attrs:{"href":"https://www.linkedin.com/in/hassan-abbas-b4483744","link":"","icon":"","rounded":"","outlined":""}},[_c(VIcon,[_vm._v("mdi-linkedin")])],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }