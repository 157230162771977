<template>
    <main>
        <v-container class="my-4">
            <v-card v-if="isLoading" :loading="isLoading">
                <v-card-text>
                    Loading..
                </v-card-text>
            </v-card>
            <v-card v-else>
                <v-card-title>
                    Booking Confirmed
                    <v-spacer></v-spacer>
                    <small><strong class="text-uppercase">M-{{ confirmationCode }}78B1AD</strong></small>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Your booking confirmation is <strong class="text-uppercase">M-{{ confirmationCode }}</strong>.
                    <br>
                    Thank you for booking with use. You'll recive email confirmation shortly.

                    Team <br>
                    Meissa London
                </v-card-text>
            </v-card>
        </v-container>
    </main>
</template>

<script>
import axios from "axios";

export default {
    name: "Confirmation",
    data() {
        return {
            confirmationCode: "",
            isLoading: false,
            storedBookingDetails: null,
        }
    },
    methods: {
        retriveStoredBooking() {
            try {
                const bookingDetails = JSON.parse(localStorage.getItem("bookingDetails"));
                this.storedBookingDetails = bookingDetails;
                // console.log(this.storedBookingDetails);
            } catch (error) {
                console.log(error);
            }
        },
        async sendBookingDetails() {
            const response = await axios.post("https://meissalondon.herokuapp.com/api/frontendBooking/create", this.storedBookingDetails);
            
            if (response.data.code === 403) {
                alert(response.data.message);
            }

            if (response.data.code === 200) {
                console.log(response.data);
                this.confirmationCode = response.data.booking._id.slice(response.data.booking._id.length -4, response.data.booking._id.length);
                this.isLoading = false;
            }
        }
    },
    created() {
        this.retriveStoredBooking();
        this.sendBookingDetails();
    }
}
</script>

<style scoped>

</style>
