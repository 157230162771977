import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c(VContainer,[_c(VSelect,{attrs:{"label":"Select a service","value":_vm.searchInput,"items":['Our Team', 'Massage', 'Beauty', 'Hair & Makeup', 'Fitness']},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}})],1),_c(VContainer,[_c(VRow,_vm._l((_vm.filteredProfiles),function(person){return _c(VCol,{key:person.id,attrs:{"cols":"12","sm":"12","md":"6","lg":"3"}},[_c(VCard,[_c(VImg,{staticClass:"white--text align-end",attrs:{"height":"260","src":require("@/assets/placeholder.png"),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"},on:{"click":function($event){person.reveal = true}}},[_c(VCardText,[_c('span',{staticClass:"text-h6"},[_vm._v(_vm._s(person.name))]),_vm._v(" "),_c('br'),_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(person.profile))])])],1),_c(VExpandTransition,[(person.reveal)?_c(VCard,{staticClass:"transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%"}},[_c(VCardText,{staticClass:"pb-0"},[_c('p',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(person.profile)+" ")]),_c('p',[_vm._v(" "+_vm._s(person.bio)+" ")])]),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){person.reveal = false}}},[_vm._v("Close")])],1)],1):_vm._e()],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }