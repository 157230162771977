<template>
    <main>
        <Heading title="About Us" body="We are thrilled to introduce ourselves as a team of
 experienced and certified therapists and beauticians, dedicated to providing exceptional
 services in the comfort of your own hotel, home or office. With our convenient and
 flexible scheduling options, we strive to make self-care accessible to everyone, no
 matter how busy your lifestyle may be." />
        <OurStory />
        <OurTeam />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import OurStory from "@/components/about/OurStory.vue";
import OurTeam from "@/components/about/OurTeam.vue";

export default {
    name: "About",
    metaInfo: {
        title: "About us",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        OurStory,
        OurTeam,
    }
}
</script>

<style scoped>

</style>
