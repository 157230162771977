<template>
    <div class="main mt-4">
        <v-container>
            <h1>Our Vision</h1>

            <p class="text-justify">
                Our vision is to provide an online platform that seamlessly connects our clients with our team of experienced and certified therapists. We strive to provide a user-friendly website that offers an exceptional user experience and inspires confidence in our services. Our website is a one-stop-shop for all your massage, beauty, hair, makeup needs, providing easy-to-use features such as real-time appointment booking, secure online payment, and a comprehensive list of our services.
            </p>
            <p class="text-justify">
                We will aim to prioritize the needs of our clients, offering personalized recommendations based on their unique preferences and needs. Detailed information will be provided about each massage technique and the benefits of each, making it easy for our clients to make an informed decision when booking an appointment.
            </p>
            <p class="text-justify">
                Overall, our vision is to create a platform that showcases the exceptional quality of our mobile services and makes it easy for our clients to book appointments and enjoy the benefits of luxury at their convenience.
            </p>
            <v-btn href="/contact" link rounded outlined large>Get in touch</v-btn>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "OurStory"
}
</script>

<style scoped>

</style>
