<template>
    <section>
        <v-container>
            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/blow-dry.jpg"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Blow Dry</h1>
                    <p class="subtitle text-justify">
                        By selecting a lovely Blow Dry treatment, you can make your hair big and bouncy or sleek and lustrous. You may enjoy getting the appearance you want from the comfort of your home by having one of our skilled stylists walk you through the style you want to achieve. Many hairstyles are available, including straight hair, bouncy waves, and beach waves.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/hair-up.jpg"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Hair Up</h1>
                    <p class="subtitle text-justify">
                        Our Up styling service will come in handy when you need to look absolutely stunning since we design hairstyles that will complement and draw attention to your natural characteristics. Your desired Instagram style will be realised with the assistance of our skilled hairstylists. We can meet all of your hair needs, whether you like curly, straight, plaited, piled on top of your head, or another style entirely.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/makeup.jpg"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Makeup</h1>
                    <p class="subtitle text-justify">
                        Our makeup artists are trained in a variety of techniques, from natural to glam, and will work with you to create a look that makes you feel confident and beautiful. We use only the highest quality makeup products to ensure that your makeup looks flawless and lasts all day.
                    </p>
                    <p class="text-justify">
                        We also offer bridal hair and makeup services to ensure that you look and feel your best on your special day. Our team is dedicated to providing you with a stress-free and enjoyable experience, and we will work with you to create the perfect bridal look that enhances your natural beauty.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import aosMixin from "@/mixins/aos.js"

export default {
    name: "MakeupShowcase",
    mixins: [aosMixin],
}
</script>

<style scoped>

</style>
