<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view/>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from "@/components/global/NavBar.vue";
import Footer from "./components/global/Footer.vue";

export default {
  name: 'App',
  metaInfo: {
    title: 'Welcome to Meissa London',
    titleTemplate: '%s | Meissa London',
    meta: [
      {
        name: 'description',
        content: "If you're looking to boost the online visibility of Meissa London, a website that offers massage, beauty, hair and makeup, and personal training services in London, there are several SEO tactics to consider. Firstly, optimizing the website's content with relevant keywords and meta descriptions can help improve its search engine ranking. Additionally, building backlinks from other reputable websites and directories can also boost its visibility. Utilizing social media platforms to promote services and generate engagement can also increase website traffic. Ensuring the website is mobile-friendly and has fast loading times can improve the user experience and encourage return visits. By implementing these SEO tactics, Meissa London can attract more clients and grow its online presence in London city.",
      }
    ]
  },
  components: {
    Navbar,
    Footer,
  },
  data: () => ({
    //
  }),
};
</script>
