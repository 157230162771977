<template>
    <div class="main">
        <NavDesktop class="desktop" />
        <NavMobile class="mobile" />
    </div>
</template>

<script>
import NavDesktop from './NavDesktop.vue'
import NavMobile from './NavMobile.vue'

export default {
    name: "NavBar",
    components: {
        NavDesktop,
        NavMobile
    }
}
</script>

<style scoped>
.mobile {
    display: none;
}

.desktop {
    display: block;
}

@media screen and (max-width: 920px) {
    .mobile {
        display: block;
    }
    .desktop {
        display: none;
    }
}
</style>