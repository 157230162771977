import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c(VContainer,{staticClass:"my-4"},[(_vm.isLoading)?_c(VCard,{attrs:{"loading":_vm.isLoading}},[_c(VCardText,[_vm._v(" Loading.. ")])],1):_c(VCard,[_c(VCardTitle,[_vm._v(" Booking Confirmed "),_c(VSpacer),_c('small',[_c('strong',{staticClass:"text-uppercase"},[_vm._v("M-"+_vm._s(_vm.confirmationCode)+"78B1AD")])])],1),_c(VDivider),_c(VCardText,[_vm._v(" Your booking confirmation is "),_c('strong',{staticClass:"text-uppercase"},[_vm._v("M-"+_vm._s(_vm.confirmationCode))]),_vm._v(". "),_c('br'),_vm._v(" Thank you for booking with use. You'll recive email confirmation shortly. Team "),_c('br'),_vm._v(" Meissa London ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }