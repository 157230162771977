<template>
    <main>
        <Heading title="Beauty Treatments" />
        <BeautyShowcase />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import BeautyShowcase from '@/components/services/BeautyShowcase.vue';

export default {
    name: "Beauty",
    metaInfo: {
        title: "Beauty",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        BeautyShowcase
    }
}
</script>

<style scoped>

</style>
