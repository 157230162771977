<template>
    <main>
        <Heading title="Our Team" body='We are proud to have a team of experienced professionals who have honed their skills
 through years of practice and experience and who are committed to staying up-to-date
 with the latest industry trends and techniques.". Our team shares a common goal of
 providing the highest level of service to our clients. They have a deep understanding of
 the needs of our clients and the ability to deliver exceptional results.' />
        <Staff />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import Staff from "@/components/therapists/Staff.vue";

export default {
    name: "Therapists",
    metaInfo: {
        title: "Team",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        Staff,
    }
}
</script>

<style scoped>

</style>
