<template>
    <div class="main mt-4 py-4">
        <v-container>
            <h1 class="my-4 text-center">Meet The Founders</h1>

            <v-row>
                <v-col color="transparent" cols="12" sm="12" md="6" lg="6">
                    <v-card width="300px" color="#fefefe" class="mx-auto" elevation="0">
                        <v-img height="250px" src="@/assets/maninder.jpg"></v-img>
                    
                        <v-card-text>
                            <div class="arrange">
                                <p>
                                    <strong>Maninder Pal Singh</strong>
                                    <br>
                                    Co-Founder
                                </p>
                                <v-btn href="https://www.linkedin.com/in/maninder-singh-16b4066b" link icon rounded outlined>
                                    <v-icon>mdi-linkedin</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col color="transparent" cols="12" sm="12" md="6" lg="6">
                    <v-card width="300px" color="#fefefe" class="mx-auto" elevation="0">
                        <v-img height="250px" cover src="@/assets/hassan.jpg"></v-img>
                    
                        <v-card-text>
                            <div class="arrange">
                                <p>
                                    <strong>Hassan Abbas</strong>
                                    <br>
                                    Co-Founder
                                </p>
                                <v-btn href="https://www.linkedin.com/in/hassan-abbas-b4483744" link icon rounded outlined>
                                    <v-icon>mdi-linkedin</v-icon>
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "OurTeam"
}
</script>

<style scoped>
.main {
    background: #bfbfbfae;
}
.arrange {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
}
</style>
