<template>
    <div class="main">
        <v-container>
            <v-form>
                <h3>Personal Details</h3>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field v-model.trim="firstName" label="Enter First Name"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field v-model.trim="lastName" label="Enter Last Name"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field v-model.trim="email" label="Enter Email Address"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field v-model="mobile" label="Enter Mobile Number"></v-text-field>
                    </v-col>
                </v-row>

                <h3>Hotel Details</h3>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-autocomplete v-model="postcode" :items="postcodeList" label="Enter Postcode"></v-autocomplete>
                        <!-- <v-select v-model="postcode" :items="postcodeList" label="Enter Postcode" placeholder="ex: SW11 8AE"></v-select> -->
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-autocomplete v-model="hotel" :items="hotelList" label="Select Hotel"></v-autocomplete>
                        <!-- <v-select v-model="hotel" label="Select Hotel" :items="hotelList" placeholder="ex: hilton"></v-select> -->
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-text-field v-model="roomNumber" label="Enter Room Number" placeholder="ex: 702"></v-text-field>
                    </v-col>
                </v-row>

                <h3 class="mt-4">Select Time & Service</h3>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date" label="Pick a date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>

                            <v-date-picker v-model="date" :min="new Date().toISOString().substr(0, 10)" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(date)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-select v-model="service" @change="setTreatments" label="Select Service" :items="['Hair & Makeup', 'Beauty', 'Massage', 'Fitness & Yoga']"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-select v-model="treatment" :items="treatmentList" label="Select Treatment"></v-select>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-select v-model="time" label="Select Your Time" :items="timeList"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                        <v-select v-model="duration" label="Select Duration" :items="durationList"></v-select>
                    </v-col>
                </v-row>


                <v-row >
                    <v-col v-if="service && treatment" cols="12" md="12" lg="12">
                        <v-card>
                            <v-card-title>
                                All our therapists are busy at the moment please check again later.
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- <v-row v-if="service && treatment">
                    <v-col v-for="employee in staffList" :key="employee.id" cols="12" sm="12" md="6" lg="3">
                        <v-card>
                            <v-img class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px" src="@/assets/placeholder.png">
                                <v-card-title>{{ employee.firstName }}</v-card-title>
                            </v-img>
                            <v-card-actions>
                                <v-btn @click="therapist = employee.firstName" rounded outlined small color="primary">select</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row> -->


                <!-- <v-row v-if="treatment && date && time && duration && therapist && hotel && postcode && roomNumber">
                    <v-col cols="12" sm="12" md="12" lg="12">
                        <v-card>
                            <v-card-title>Summary</v-card-title>
                            <v-card-text>
                                <strong>Therapist:</strong> {{ this.therapist }} <br>
                                <strong>Treatment:</strong> {{ this.treatment }} <br>
                                <strong>Date:</strong> {{ date }} <br>
                                <strong>Time:</strong> {{ time }} <br>
                                <strong>Duration:</strong> {{ duration }} <br>
                                <strong>Therapist:</strong> {{ therapist }} <br>
                                <strong>Hotel:</strong> {{ hotel }}, {{ postcode }} <br>
                                <strong>Room:</strong> {{  roomNumber }} <br>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row> -->

                <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishableKey" :line-items="lineItems" :success-url="successURL" :cancel-url="cancelURL" @loading="v => loading = v"></stripe-checkout>
                <v-btn @click="submit" :disabled="!(treatment && date && time && duration && therapist && hotel && postcode && roomNumber)" class="my-4" color="primary" rounded>Go to checkout</v-btn>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
    name: "Form",
    components: {
        StripeCheckout
    },
    data() {
        // stripe config
        this.publishableKey = "pk_test_51M2DhNKIxIDG2pfUgFvjcxfuHp3avCdJ8Pjtpht5N8aaWd8ihgpju8bObNxVXukkq1hYuuvtdaRYxfsbzIqH7yMU00YPaeEyIu";

        return {
            // payment controls
            lineItems: [],
            loading: false,
            successURL: "https://meissalondon.com/redirect/confirmed",
            cancelURL: "https://meissalondon.com/redirect/cancelled",

            // location controls
            hotelList: [],
            postcodeList: [],

            locationList: null,
            treatmentList: null,
            timeList: null,
            durationList: null,
            services: {
                makeup: [
                    "Blow-dry",
                    "Hair up & Styling",
                    "Gents Haircut",
                    "Ladis haircut & Blow Dry",
                    "Makeup",
                ],
                beauty: [
                    "Anti Aging Facial",
                    "Manicure",
                    "Pedicure",
                    "Manicure & Pedicure",
                    "Express Manicure & Pedicure",
                    "Gents Manicure & Pedicure",
                    "Shellac Manicure",
                    "Shellac Pedicure",
                    "Shellac Manicure & Pedicure",
                    "Shellac Manicure & Pedicure + Removal",
                    "Shellac Manicure or Pedicure + Removal",
                ],
                massage: [
                    "Deep Tissue Massage",
                    "Swedish/Holistic Massage",
                    "Reflexology",
                    "Sports Massage",
                    "Thai Massage",
                    "Pregnancy Massage",
                    "Lymphatic Drainage Massage",
                ],
                fitness: [
                    "Yoga",
                    "Personal Training",
                ],
            },

            // control fields
            firstName: "",
            lastName: "",
            email: "",
            mobile: "",
            postcode: "",
            hotel: "",
            time: "",
            roomNumber: "",
            service: "",
            treatment: "",
            duration: "",
            therapist: "",
            amount: "",


            // for date pickers
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,

            // staff list
            staffList: [
                {
                    id: 0,
                    firstName: "Maili",
                    // imgSrc: "",
                    profile: "therapist",
                }
            ]
        }
    },
    methods: {
        setTreatments() {
            switch (this.service) {
                case "Hair & Makeup":
                    // set treatments
                    this.treatmentList = this.services.makeup;
                    // set time
                    this.timeList = this.getTimes.makeup;
                    // set durations
                    this.durationList = this.getDurations.makeup;
                    break;
                case "Beauty":
                    // set treatments
                    this.treatmentList = this.services.beauty;
                    // set time
                    this.timeList = this.getTimes.beauty;
                    // set durations
                    this.durationList = this.getDurations.beauty;
                    console.log(this.durationList);
                    break;
                case "Massage":
                    // set treatments
                    this.treatmentList = this.services.massage;
                    // set time
                    this.timeList = this.getTimes.massage;
                    // set durations
                    this.durationList = this.getDurations.massage;
                    break;
                case "Fitness & Yoga":
                    // set treatments
                    this.treatmentList = this.services.fitness;
                    // set time
                    this.timeList = this.getTimes.fitness;
                    // set durations
                    this.durationList = this.getDurations.fitness;
                    break;
                default:
                    break;
            }
        },
        setLocations(array) {
            this.postcodeList = array.map((item) => item.postcode);
            this.hotelList = array.map((item) => item.hotel);
            this.locationList = array;
        },
        submit() {
            if (/^(?:0|\+?44)(?:\d\s?){9,10}$/.test(this.mobile)) {
                this.storeDetails();
                this.$refs.checkoutRef.redirectToCheckout();
            } else {
                return;
            }
        },
        storeDetails() {
            let bookingDetails = {
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                mobile: this.mobile,
                hotelName: this.hotel,
                roomNumber: this.roomNumber,
                service: this.service,
                treatment: this.treatment,
                therapist: this.therapist,
                address: "",
                postcode: this.postcode,
                date: this.date,
                time: this.time,
                duration: this.duration,
                paymentMethod: "prepaid",
                amount: this.amount,
                comment: "",
            }

            localStorage.setItem("bookingDetails", JSON.stringify(bookingDetails));
        }
    },
    watch: {
        treatment(val) {
            console.log(val);

            if (val == "Blow-dry" || val == "Hair up & Styling" || val == "Gents Haircut" || val == "Ladis haircut & Blow Dry" || val == "Makeup") {
                return this.durationList = ["1 hour"];
            }

            if (val == "Anti Aging Facial" || val == "Manicure" || val == "Pedicure" || val == "Shellac Manicure" || val == "Shellac Pedicure") {
                return this.durationList = ["1 hour"];
            }

            if (val == "Express Manicure & Pedicure" || val == "Gents Manicure & Pedicure" || val == "Shellac Manicure or Pedicure + Removal") {
                return this.durationList = ["1 hour 30 mins"];
            }
            
            if (val == "Manicure & Pedicure" || val == "Shellac Manicure & Pedicure") {
                return this.durationList = ["2 hours"];
            } else {
                this.setTreatments(this.service);
            }
        },
        postcode(val) {
            this.locationList.find((item) => {
                if (item.postcode == val) {
                    this.hotel = item.hotel;
                }
            });
        },
        hotel(val) {
            this.locationList.find((item) => {
                if (item.hotel == val) {
                    this.postcode = item.postcode;
                }
            });
        },
        duration(val) {
            switch (val) {
                case '1 hour':
                    this.lineItems = [];
                    this.amount = "120.00";
                    this.lineItems.push({ price: "price_1MoUtzKIxIDG2pfUZwHjnafk", quantity: 1 }); // 1 hour
                    break;
                case '1 hour 30 mins':
                    this.lineItems = [];
                    this.amount = "180.00";
                    this.lineItems.push({ price: "price_1MoUvxKIxIDG2pfUUrm3QH2v", quantity: 1 }); // 1.5 hour
                    break;
                case '2 hours':
                    this.lineItems = [];
                    this.amount = "240.00";
                    this.lineItems.push({ price: "price_1MoUwZKIxIDG2pfUDpCtzZzy", quantity: 1 }); // 2 hour
                    break;
                default:
                    this.lineItems = [];
                    break;
            }
        }
    },
    computed: {
        ...mapGetters(["getTimes", "getDurations", "getLocations"])
    },
    created() {
        this.setLocations(this.getLocations);
    }
}
</script>

<style scoped>

</style>
