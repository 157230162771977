<template>
    <section>
        <v-container>
            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/shellac.jpg"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Shellac Manicure & Pedicure</h1>
                    <p class="subtitle text-justify">
                        Shellac pedicure combines the ease of traditional nail polish with the strength and flexibility of gel, the popular Shellac polish is incredibly durable, ensuring your pretty pedicure lasts longer. With a Shellac pedicure, your nails are less likely to chip or fade. Your treatment will include soaking and neatening of cuticles and trimming and filing of toenails, foot scrub and massage, as well as the application of a Shellac polish if you currently have Shellac Gel applied, an additional 30 minutes is required for removal prior to reapplication Shellac manicure is a type of gel manicure that involves applying a special nail polish made with a combination of gel and traditional nail polish ingredients. The polish is cured or hardened using a UV or LED light, resulting in a long-lasting and chip-resistant manicure that can last up to two weeks. The process involves several steps, including preparing the nails, applying a base coat, two coats of Shellac polish, and a topcoat.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/3985329/pexels-photo-3985329.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Classic Facial</h1>
                    <p class="subtitle text-justify">
                        A classic facial is a tailored treatment to the needs of your skin type. This includes cleansing, exfoliation, and moisturizing of the skin followed by a facial massage and mask treatment to ensure you are fully relaxed with a glowing complexion. Anti-Aging Facial: An anti-aging facial is designed to address the signs of aging, such as fine lines, wrinkles, and age spots. It includes specialized treatments, such as collagen or retinol, to help reduce the appearance of aging.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/manicure.jpg"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Luxury Manicure</h1>
                    <p class="subtitle text-justify">
                        Luxury manicure with Meissa London is a pampering nail treatment that goes beyond the standard manicure.It includes a warm hand soak to soften the skin, followed by exfoliation of the hands to remove dead skin cells. Cuticle care is then performed, including trimming and pushing back the cuticles. Next, the nails are shaped, buffed, and polished to a shine. A luxury manicure is an excellent way to treat yourself or someone special to a relaxing and indulgent experience. Indulge in one of these treatments for yourself, or surprise a loved one with a treat for their hand and nail care needs with one of our specialist beauticians.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="@/assets/pedicure.jpg"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Luxury Pedicure</h1>
                    <p class="subtitle text-justify">
                        Luxury pedicure is an experience unlike any other. From the moment our technicians arrive for your appointment, you'll be pampered like a queen. The procedure includes Foot Soak,Exfoliating,Cuticle Care and nail care. Your technician will pamper you with a luxurious foot soak, then buff and file your nails to perfection. Next, she'll moisturize your skin and treat your cuticles to a special massage. Finally, you'll get a deluxe pedicure that includes beautiful nail polish for the ultimate finishing touch.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import aosMixin from "@/mixins/aos.js"

export default {
    name: "BeautyShowcase",
    mixins: [aosMixin],
}
</script>

<style scoped>

</style>
