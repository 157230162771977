<template>
    <div class="main">
        <v-container>
            <header>
                <div class="logo">
                    <router-link to="/">
                        <v-img src="@/assets/ml-logo.png"></v-img>
                    </router-link>
                </div>

                <nav>
                    <ul>
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <router-link to="/about">About</router-link>
                        </li>
                        <li>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <a href="#!" v-bind="attrs" v-on="on">Services</a>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <router-link to="/massage">Massage</router-link>
                                    </v-list-item>
                                    <v-list-item>
                                        <router-link to="/beauty">Beauty</router-link>
                                    </v-list-item>
                                    <v-list-item>
                                        <router-link to="/makeup">Hair & Makeup</router-link>
                                    </v-list-item>
                                    <v-list-item>
                                        <router-link to="/fitness-yoga">Fitness</router-link>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </li>
                        <li>
                            <router-link to="/therapists">Team</router-link>
                        </li>
                        <li>
                            <router-link to="/contact">Contact</router-link>
                        </li>
                        <li>
                            <v-btn href="/booking" link rounded color="#333" class="white--text" small>Book Now</v-btn>
                        </li>
                    </ul>
                </nav>
            </header>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "NavDesktop"
}
</script>

<style scoped>
.main {
    background: #F4F2E8;
    box-shadow: 0 0 4px #636e72;
}

header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

nav ul {
    display: flex;
    flex-direction: row;
}

nav ul li {
    list-style-type: none;
    margin-left: 22px;
}

a {
    text-decoration: none;
    color: #333 !important;
    transition: all .3s ease;
}

a:hover {
    opacity: 0.8;
}

.router-link-exact-active {
    font-weight: bold;
}
</style>
