import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c(VContainer,[_c('header',[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{attrs:{"src":require("@/assets/ml-logo.png")}})],1)],1),_c('nav',[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About")])],1),_c('li',[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({attrs:{"href":"#!"}},'a',attrs,false),on),[_vm._v("Services")])]}}])},[_c(VList,[_c(VListItem,[_c('router-link',{attrs:{"to":"/massage"}},[_vm._v("Massage")])],1),_c(VListItem,[_c('router-link',{attrs:{"to":"/beauty"}},[_vm._v("Beauty")])],1),_c(VListItem,[_c('router-link',{attrs:{"to":"/makeup"}},[_vm._v("Hair & Makeup")])],1),_c(VListItem,[_c('router-link',{attrs:{"to":"/fitness-yoga"}},[_vm._v("Fitness")])],1)],1)],1)],1),_c('li',[_c('router-link',{attrs:{"to":"/therapists"}},[_vm._v("Team")])],1),_c('li',[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("Contact")])],1),_c('li',[_c(VBtn,{staticClass:"white--text",attrs:{"href":"/booking","link":"","rounded":"","color":"#333","small":""}},[_vm._v("Book Now")])],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }