<template>
    <div class="main my-4">
        <v-container>
            <footer>
                <v-divider></v-divider>
                <v-row class="py-4">
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <h3>Get in touch</h3>
                        <p class="">
                            7 Farquharson Road, <br>
                            Croydon, <br>
                            England<br>
                            Post Code: CR0 2UH <br><br>
                            Telephone: <br>
                            <a href="tel:02080809898">020 8080 9898</a> <br>
                            Email Address: <br>
                            <a href="mailto:bookings@meissalondon.com">bookings@meissalondon.com</a>
                        </p>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" lg="4">
                        <h3>Services</h3>
                        <p>
                            <router-link to="/massage">Massage</router-link> <br>
                            <router-link to="/beauty">Beauty</router-link> <br>
                            <router-link to="/fitness-yoga">Fitness</router-link> <br>
                            <router-link to="/makeup">Hair & Makeup</router-link> <br>
                        </p>
                    </v-col>

                    <!-- <v-col cols="12" sm="12" md="6" lg="3">
                        <h3>Team</h3>
                        <p>
                            <a href="#!">Meet The Team</a> <br>
                            <a href="#!">Join us</a> <br>
                        </p>
                    </v-col> -->

                    <v-col cols="12" sm="12" md="6" lg="4">
                        <h3>Policies & Conditions</h3>
                        <p>
                            <router-link to="/tc">Terms & Conditions</router-link> <br>
                            <router-link to="/cp">Cancellation Policy</router-link> <br>
                            <router-link to="/pp">Privacy Policy</router-link>
                        </p>
                    </v-col>
                </v-row>
                <v-divider></v-divider>

                <p class="socials text-center">
                    <v-btn style="text-decoration: none;" class="ma-2" link href="mailto:bookings@meissalondon.com" color="#2c3e50" elevation="1" large icon><v-icon>mdi-email</v-icon></v-btn>
                    <v-btn style="text-decoration: none;" class="ma-2" link target="_blank" href="https://www.facebook.com/profile.php?id=100090041560819" color="#2c3e50" elevation="1" large icon><v-icon>mdi-facebook</v-icon></v-btn>
                    <v-btn style="text-decoration: none;" class="ma-2" color="#2c3e50" elevation="1" large target="_blank" href="https://instagram.com/meissa_london23?igshid=YmMyMTA2M2Y=" icon><v-icon>mdi-instagram</v-icon></v-btn>
                    <v-btn style="text-decoration: none;" class="ma-2" color="#2c3e50" elevation="1" large href="tel:02080809898" icon><v-icon>mdi-phone</v-icon></v-btn>
                </p>
                <div id="google_translate_element"></div>

                <p class="caption text-center">
                    &copy; Copyrights Meissa London, 2023
                </p>
            </footer>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #2d3436 !important;
}
a:hover {
    text-decoration: underline;
}
</style>
