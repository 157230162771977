<template>
    <div class="main">
        <v-app-bar color="#F4F2E8" elevation="2">
            <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
            <v-toolbar-title style="padding: 0;">
                <router-link to="/">
                    <v-img width="140px" src="@/assets/logo-very-small.jpg"></v-img>
                </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn href="/booking" link elevation="0" rounded small color="#333" class="white--text">
                <strong>Book Now</strong>
            </v-btn>
        </v-app-bar>

        <!-- drawer -->
        <v-navigation-drawer class="fix-index" v-model="drawer" absolute temporary>
            <v-list nav dense>
                <v-list-item-group v-model="group">
                    <router-link to="/">
                        <v-list-item>
                            <v-list-item-title>Home</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/about">
                        <v-list-item>
                            <v-list-item-title>About</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/massage">
                        <v-list-item>
                            <v-list-item-title>Massage</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/beauty">
                        <v-list-item>
                            <v-list-item-title>Beauty</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/makeup">
                        <v-list-item>
                            <v-list-item-title>Hair & Makeup</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/fitness-yoga">
                        <v-list-item>
                            <v-list-item-title>Fitness</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/therapists">
                        <v-list-item>
                            <v-list-item-title>Our Team</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/contact">
                        <v-list-item>
                            <v-list-item-title>Contact</v-list-item-title>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: "NavMobile",
    data() {
        return {
            drawer: false,
            group: null,
        }
    }
}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #474F74 !important;
}
</style>
