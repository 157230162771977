<template>
    <div class="main">
        <div class="overlay"></div>
        <v-container data-aos="fade-up" class="fix-index">
            <div class="icon">
                <v-img src="@/assets/watermark-bg.png" width="120px" height="100px" class="mx-auto circle"></v-img>
            </div>
            <h1 class="text-center white--text">
                {{ title }}
            </h1>
            <p class="text-center white--text">
                {{ body }}
            </p>
        </v-container>
    </div>
</template>

<script>
import aosMixin from "@/mixins/aos.js"

export default {
    name: "Heading",
    props: {
        title: String,
        body: String,
    },
    mixins: [aosMixin],
}
</script>

<style scoped>
.main {
    height: 40vh;
    background: url(https://images.pexels.com/photos/3757942/pexels-photo-3757942.jpeg?auto=compress&cs=tinysrgb&w=800) no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.main .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #00000086;
}

.fix-index {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 920px) {
    .main {
        height: 350px;
    }
}
</style>
