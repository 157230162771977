<template>
    <section>
        <v-container>
            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/3757942/pexels-photo-3757942.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Massage</h1>
                    <p class="subtitle">
                        At Meissa London we understand that each client has different needs and goals, and our massage treatments are tailored to meet those needs. Whether you're looking to relieve stress, ease tension, or simply relax and unwind, we have the perfect massage treatment for you in the comfort of your own space.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/2036646/pexels-photo-2036646.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Hair & Makeup</h1>
                    <p class="subtitle">Our team of expert stylists are dedicated to helping you look and feel your best. Our hairdressing services range from haircuts and coloring to extensions and styling. Our experienced stylists are up-to-date with the latest trends and techniques to provide you with a hairstyle that perfectly suits your face shape, lifestyle, and personality.</p>
                    <p class="subtitle">
                        Our makeup artists are trained in a variety of techniques, from natural to glam, and will work with you to create a look that makes you feel confident and beautiful. We use only the highest quality makeup products to ensure that your makeup looks flawless and lasts all day.
                    </p>
                    <p>
                        We also offer bridal hair and makeup services to ensure that you look and feel your best on your special day. Our team is dedicated to providing you with a stress-free and enjoyable experience, and we will work with you to create the perfect bridal look that enhances your natural beauty.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/4783332/pexels-photo-4783332.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Beauty</h1>
                    <p class="subtitle">
                        Beauty services like manicures, pedicures, and facials are popular treatments that people often get to enhance their nails and skin. A manicure is a hand treatment that includes trimming and shaping nails, removing cuticles, and applying nail polish. Similarly, a pedicure is a foot treatment that includes trimming and shaping toenails, removing dead skin, and applying nail polish. A facial involves cleansing, exfoliating, and moisturizing the skin on the face using specialized treatments tailored to an individual's skin type and needs. Beauty services promote self-care and relaxation, boost confidence, and improve one's physical appearance.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/414029/pexels-photo-414029.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Personal Training</h1>
                    <p class="subtitle">
                        Personal training is a form of exercise coaching that is tailored to an individual's specific needs and goals. A personal trainer provides one-on-one guidance and instruction to help the client achieve their fitness objectives. This may involve designing personalized workout routines, demonstrating proper exercise techniques, providing nutritional advice, and monitoring progress. Personal trainers can help clients improve their overall health, increase strength and flexibility, and lose weight or build muscle. They also provide motivation and accountability to help clients stay on track and achieve their fitness goals. Personal training is a great way to receive personalized support and guidance to reach your fitness potential.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import aosMixin from "@/mixins/aos.js";

export default {
    name: "ShowCase",
    mixins: [aosMixin],
}
</script>

<style scoped>

</style>
