<template>
    <main>
        <v-container class="my-4">
            <v-card>
                <v-card-title>Booking Process Failed</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    Booking process failed. This could have happend because of 
                    <ul>
                        <li>bad internet connection</li>
                        <li>payment method was declined</li>
                        <li>payment process was cancelled</li>
                    </ul>
                    If you're facing difficulty making online payment please get in touch with us <br>
                    Send an email: <a href="mailti:bookings@meissalondon.com">bookings@meissalondon.com</a> or
                    Call us: <a href="tel:02080809898">020-8080-9898</a>
                </v-card-text>
            </v-card>
        </v-container>
    </main>
</template>

<script>
export default {
    name: "Cancel"
}
</script>

<style scoped>

</style>
