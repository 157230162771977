import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c(VContainer,{staticClass:"my-4"},[_c(VCard,[_c(VCardTitle,[_vm._v("Booking Process Failed")]),_c(VDivider),_c(VCardText,[_vm._v(" Booking process failed. This could have happend because of "),_c('ul',[_c('li',[_vm._v("bad internet connection")]),_c('li',[_vm._v("payment method was declined")]),_c('li',[_vm._v("payment process was cancelled")])]),_vm._v(" If you're facing difficulty making online payment please get in touch with us "),_c('br'),_vm._v(" Send an email: "),_c('a',{attrs:{"href":"mailti:bookings@meissalondon.com"}},[_vm._v("bookings@meissalondon.com")]),_vm._v(" or Call us: "),_c('a',{attrs:{"href":"tel:02080809898"}},[_vm._v("020-8080-9898")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }