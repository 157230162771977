<template>
    <main>
        <Heading title="Get Beautify With Us" />
        <MakeupShowcase />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import MakeupShowcase from '@/components/services/MakeupShowcase.vue';

export default {
    name: "Makeup",
    metaInfo: {
        title: "Hair & Makeup",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        MakeupShowcase
    }
}
</script>

<style scoped>

</style>
