<template>
    <div class="main">
        <div class="overlay"></div>

        <div class="wrapper">
            <v-container data-aos="fade-up" class="text-center fix-index">
                <div class="icon">
                    <v-img src="@/assets/watermark-bg.png" width="120px" height="100px" class="mx-auto circle"></v-img>
                </div>
                <h1 class="white--text">Meissa London</h1>
                <p class="white--text">
                    Recharge Your Body and Mind: Discover the Healing Benefits of Our Massage and Beauty Treatments.
                </p>
                <v-btn href="/booking" class="mt-4" color="white" link large outlined rounded>Book Now</v-btn>
            </v-container>
        </div>
    </div>
</template>

<script>
import aosMixin from "@/mixins/aos.js";

export default {
    name: "LandingArea",
    mixins: [aosMixin],
}
</script>

<style scoped>
.main {
    height: 80vh;
    background: url(https://images.pexels.com/photos/3764568/pexels-photo-3764568.jpeg?auto=compress&cs=tinysrgb&w=800) no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fix-index {
    position: relative;
    z-index: 3;
}

.main .overlay {
    background: #00000086;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


@media screen and (max-width: 920px) {
    .main {
        height: 450px;
    }
}
</style>
