<template>
    <div class="main">
        <v-container>
            <v-row data-aos="fade-up" class="my-4">
                <v-col class="text-justify" cols="12" lg="6" md="6" sm="12">
                    <h2>For Booking Related Details</h2>
                    <p>
                        Thank you for choosing Meissa London for your treatment. To make a reservation or to inquire about our services, please contact us using one of the methods.
                    </p>
                    <p>
                        Our website offers an easy and convenient online booking system. Simply visit our website and follow the prompts to book your reservation. <router-link to="/booking">Book Online</router-link>
                    </p>
                </v-col>
                <v-col class="text-justify" cols="12" lg="6" md="6" sm="12">
                    <v-list two-line>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="primary">mdi-phone</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>Telephone</v-list-item-title>
                                <v-list-item-subtitle><a href="tel:02080809898" style="color: #0984e3;">020 8080 9898</a></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="primary">mdi-email</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>Email</v-list-item-title>
                                <v-list-item-subtitle><a href="mailto:bookings@meissalondon.com" style="color: #0984e3;">bookings@meissalondon.com</a></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-avatar>
                                <v-icon color="#00b894">mdi-whatsapp</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>Chat on WhatsApp</v-list-item-title>
                                <v-list-item-subtitle><a href="https://api.whatsapp.com/send?phone=447780133016" target="_blank" style="color: #00b894;">Click here</a></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import aosMixin from "@/mixins/aos.js";

export default {
    name: "Content",
    mixins: [aosMixin]
}
</script>

<style scoped>
a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
</style>
