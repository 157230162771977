<template>
    <main>
        <Heading title="Make Booking Online" body="" />
        <Form />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import Form from "@/components/booking/Form.vue";

export default {
    name: "Booking",
    metaInfo: {
        title: "Book Online",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        Form,
    }
}
</script>

<style scoped>

</style>
