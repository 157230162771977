import Vue from 'vue'
import Vuex from 'vuex'

// modules
import time from './modules/time'
import duration from './modules/duration'
import location from './modules/location'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    time,
    duration,
    location
  }
})
