<template>
    <section>
        <v-container>
            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/4056723/pexels-photo-4056723.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <h1>Yoga Training</h1>
                    <p class="subtitle text-justify">
                        Yoga practise includes a variety of breathing methods and stretching stretches to balance mind and body. It is a workout for both the body and the mind. Our private yoga instructors are skilled in a variety of yoga practises and can mix them to create a session that meets your needs. When you click on each teacher's information button, you can see their teaching methods. Contact our booking line if you're looking for a specific Yoga practise.
                    </p>
                </v-col>
            </v-row>

            <v-row data-aos="fade-up" class="mt-4">
                <v-col order-md="2" order-lg="2" cols="12" sm="12" md="6" lg="6">
                    <v-img src="https://images.pexels.com/photos/416754/pexels-photo-416754.jpeg?auto=compress&cs=tinysrgb&w=800"></v-img>
                </v-col>
                <v-col order-md="1" order-lg="1" cols="12" sm="12" md="6" lg="6">
                    <h1>Personal Training</h1>
                    <p class="subtitle text-justify">
                        With our one to one Personal Training programme, a service that is totally customised to meet your individual needs, it's time to achieve your fitness goals. All our trainers have the expertise, knowledge, and credentials to assist you in reaching your objectives, whether you want to lose weight, tone up, get in shape for an event, or just improve your general fitness. Work from the convenience of your home or a park. We can also train there if you have access to a personal gym.
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import aosMixin from "@/mixins/aos.js"

export default {
    name: "FitnessShowcase",
    mixins: [aosMixin],
}
</script>

<style scoped>

</style>
