// for treatment durations only

const state = {
    makeup: [
        "1 hour", "2 hous"
    ],
    beauty: [
        "1 hour", "2 hours"
    ],
    massage: [
        "1 hour", "1 hour 30 mins", "2 hours",
    ],
    fitness: [
        "1 hour", "1 hour 30 mins", "2 hours",
    ]
}

const mutations = {

}

const actions = {

}

const getters = {
    getDurations(state) {
        return state;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
