<template>
    <main>
        <LandingArea />
        <ShowCase />
        <Parallax />
    </main>
</template>

<script>
import LandingArea from '@/components/home/LandingArea.vue';
import ShowCase from "@/components/home/ShowCase.vue";
import Parallax from '@/components/home/Parallax.vue';

export default {
    name: "Home",
    components: {
        LandingArea,
        ShowCase,
        Parallax,
    }
}
</script>

<style scoped>

</style>
