<template>
    <main>
        <div class="overlay"></div>
        <v-container class="text-content white--text text-center">
            <h1>Highlights</h1>
            <h4>
                We offer a range of personalized massage, beauty, and hair services to cater to your unique needs and preferences. Our team of experienced massage therapists, estheticians, and hair stylists are dedicated to providing you with the highest level of service and care.
            </h4>
            <v-btn class="mt-4" rounded color="#ecf0f1" href="/booking" link outlined large>Book Online</v-btn>
        </v-container>
    </main>
</template>

<script>
export default {
    name: "Parallax"
}
</script>

<style scoped>
main {
    margin-top: 32px;
    height: 450px;
    background: url("https://images.pexels.com/photos/6560265/pexels-photo-6560265.jpeg?auto=compress&cs=tinysrgb&w=800") no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #0000008f;
}

.text-content {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    z-index: 1;
}

.text-content h1 {
    font-size: 34px;
}

.text-content span {
    font-weight: bold;
    text-transform: uppercase;
}

@media screen and (max-width: 820px) {
    main {
        height: 450px;
        background: url("https://images.pexels.com/photos/6560265/pexels-photo-6560265.jpeg?auto=compress&cs=tinysrgb&w=800") no-repeat;
        /* background-attachment: fixed; */
        background-position: center;
        background-size: cover;
        position: relative;
    }
}
</style>