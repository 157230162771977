<template>
    <main>
        <Heading title="Let's get in touch" />
        <Content />
    </main>
</template>

<script>
import Heading from '@/components/global/Heading.vue';
import Content from '@/components/contact/Content.vue';

export default {
    name: "Contact",
    metaInfo: {
        title: "Contact Us",
        meta: [
            {
                name: 'description',
                content: ''
            }
        ]
    },
    components: {
        Heading,
        Content,
    }
}
</script>

<style scoped>

</style>
